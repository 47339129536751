import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Stack, Visibility } from '@krakentech/coral';
import { AccoladeBanner } from './pageSections/AccoladeBanner';
import { LctSection } from './pageSections/LctSection';
import { OverviewSection } from './pageSections/OverviewSection';
import { SmartTariffsSection } from './pageSections/SmartTariffsSection';
import { HeatpumpSection } from './pageSections/HeatpumpSection';
import { SolarSection } from './pageSections/SolarSection';
import { EvSection } from './pageSections/EvSection';
import { OctoverseSection } from './pageSections/OctoverseSection';
import { JoinOctopus } from './components/JoinOctopus/JoinOctopus';
import { Hero } from './pageSections/Hero';
export const Homepage = () => (_jsxs(_Fragment, { children: [_jsx(Stack, { justifyContent: "center", fullWidth: true, children: _jsx(AccoladeBanner, {}) }), _jsx(Stack, { justifyContent: "center", fullWidth: true, children: _jsx(Visibility, { hidden: false, md: { hidden: true, display: 'none' }, children: _jsx(Container, { padding: "md", sm: { maxWidth: 500 }, children: _jsx(JoinOctopus, {}) }) }) }), _jsx(Hero, {}), _jsx(LctSection, {}), _jsx(OverviewSection, {}), _jsx(SmartTariffsSection, {}), _jsx(HeatpumpSection, {}), _jsx(SolarSection, {}), _jsx(EvSection, {}), _jsx(OctoverseSection, {})] }));
