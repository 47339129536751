import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Stack } from '@krakentech/coral';
import styled from 'styled-components';
export const CustomCardSmall = ({ children, image, imgAltText, theme = 'mid', }) => (_jsx(StyledCard, { children: _jsx(Container, { fullWidth: true, paddingY: "xl", paddingX: "lg", theme: theme, fullHeight: true, md: { fullWidth: false }, children: _jsxs(Stack, { direction: "vertical", children: [_jsx(StyledImageWrapper, { children: _jsx(StyledImage, { src: image, alt: imgAltText }) }), children] }) }) }));
const StyledCard = styled.div `
    border-radius: 6rem;
    overflow: hidden;
    max-width: 480px;
    height: auto;
`;
const StyledImage = styled.img `
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
`;
const StyledImageWrapper = styled.div `
    overflow: hidden;
    border-radius: 2.4rem;
    height: 190px;
`;
