import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { Container, Visibility } from '@krakentech/coral';
import { JoinOctopusCard } from '../../components/JoinOctopus/JoinOctopusCard';
//@ts-ignore
import HeroImage from './home-hero.png';
export const Hero = () => (_jsx(StyledHeroContainer, { children: _jsx(Container, { md: { paddingX: 'sm', paddingY: 'sm' }, xl: { minWidth: 1200 }, children: _jsx(Visibility, { hidden: true, md: { hidden: false }, children: _jsx(JoinOctopusCard, {}) }) }) }));
const StyledHeroContainer = styled.div `
    display: flex;
    justify-content: start;
    align-items: center;
    height: 250px;
    background-position: center right;
    background-image: linear-gradient(to top, transparent 60%, #100030),
        linear-gradient(to bottom, transparent 60%, #100030), url(${HeroImage});

    background-size: cover;
    @media (min-width: 576px) {
        height: 350px;
    }
    @media (min-width: 576px) {
        height: 450px;
    }
    @media (min-width: 768px) {
        height: 600px;
        background-image: linear-gradient(to right, #100030 5%, transparent),
            url(${HeroImage});
    }
    @media (min-width: 992px) {
        background-image: linear-gradient(to left, transparent 60%, #100030),
            url(${HeroImage});
        height: 700px;
    }
    @media (min-width: 1200px) {
        height: 820px;
        justify-content: center;
    }
    @media (min-width: 1815px) {
        height: 950px;
        background-position: top 35% right;
    }
`;
