import { jsx as _jsx } from "react/jsx-runtime";
import { Container } from '@krakentech/coral';
import styled from 'styled-components';
import { JoinOctopus } from './JoinOctopus';
const CustomCard = styled.div `
    border-radius: 6rem;
    width: auto;
    overflow: hidden;
    @media (max-width: 992px) {
        max-width: 450px;
    }
    @media (min-width: 992px) {
        max-width: 520px;
    }
`;
export const JoinOctopusCard = () => (_jsx(CustomCard, { children: _jsx(Container, { paddingY: "sm", paddingX: "sm", theme: "none", maxWidth: 520, md: {
            padding: 'none',
        }, lg: {
            theme: 'dark',
            paddingY: 'md',
            paddingX: 'md',
        }, children: _jsx(Container, { padding: "md", md: { padding: 'none' }, lg: {
                padding: 'md',
            }, xl: { padding: 'xl' }, children: _jsx(JoinOctopus, {}) }) }) }));
