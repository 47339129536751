import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Stack } from '@krakentech/coral';
import styled from 'styled-components';
export const CustomCardLarge = ({ children, backgroundImage, theme = 'mid', }) => (_jsx(CustomCardContainer, { theme: theme, children: _jsxs(Stack, { direction: "vertical", lg: { direction: 'horizontal' }, fullHeight: true, children: [_jsx(Stack.Item, { flex: "1", children: _jsx(Container, { padding: "xl", lg: { padding: 'xxxl' }, children: children[0] }) }), _jsxs(StyledImageWrapper, { children: [_jsx(StyledImage, { src: backgroundImage, alt: "" }), _jsx(ImageGradient, { theme: theme }), _jsx(ImageContentContainer, { children: _jsx(Stack, { direction: "vertical", justifyContent: "end", children: children[1] }) })] })] }) }));
const StyledImageWrapper = styled.div `
    position: relative;
    flex: 1;
`;
const ImageGradient = styled.div `
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(
        to top,
        ${({ theme }) => (theme === 'dark' ? '#100030' : '#180048')} 5%,
        transparent
    );
`;
const StyledImage = styled.img `
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
const ImageContentContainer = styled.div `
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
`;
const CustomCardContainer = styled.div `
    border-radius: 6rem;
    overflow: hidden;
    background-color: ${({ theme }) => theme === 'dark' ? '#100030' : '#180048'};
`;
