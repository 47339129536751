import { createRoot } from 'react-dom/client';
import App from './App';

const scriptId = 'homepage-initialiser-script';

const renderHomePage = (scriptId) => {
    const scriptElementData = document.getElementById(scriptId).dataset;
    const elementId = scriptElementData.idSelector;

    const container = document.getElementById(elementId);
    const root = createRoot(container);
    root.render(<App />);
};

renderHomePage(scriptId);
